<!-- #################################################################################### -->
<!-- ###### HERINCO                                                                ###### -->
<!-- ###### @author: Estefania Villada Chavarria                                   ###### -->
<!-- ###### @date: Noviembre 2023                                                  ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
    <div class="contenido">
        <section class="d-flex">
            <v-text-field class="me-2 filtro" v-model="filtro.pagador" label="Pagador" outlined dense hide-details>
            </v-text-field>

            <v-text-field class="me-2 filtro" v-model="filtro.aseguradora" label="Aseguradora" outlined dense hide-details>
            </v-text-field>

            <v-select class="me-2 filtro" v-model="filtro.estado" label="Estado" outlined dense hide-details
                :items="opcionEstados" :menu-props="{ offsetY: true }">
            </v-select>

            <div>
                <v-tooltip left color="success" :open-on-focus="false">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn small fab color="success" v-bind="attrs" v-on="on"
                            @mousedown.prevent="dialogoRelacion = true">
                            <v-icon> add </v-icon>
                        </v-btn>
                    </template>
                    <span>Agregar</span>
                </v-tooltip>
            </div>
        </section>

        <v-dialog v-model="dialogoRelacion" transition="dialog-bottom-transition" max-width="30rem" persistent>
            <v-card>
                <v-card-title class="encabezadoDialogo">
                    <span class="text-h6">Crear relación</span>
                </v-card-title>

                <v-card-text class="pt-5">
                    <validation-observer ref="observer" v-slot="{ invalid }">
                        <v-form lazy-validation>
                            <!-- Campo para ingresar el pagador -->
                            <div class="d-flex">
                                <validation-provider class="campo" v-slot="{ errors }" :rules="{ required: true }">
                                    <v-autocomplete v-model="crearRelacion.pagador" label="Pagador - Detalle cliente" dense outlined :error-messages="errors"
                                        :menu-props="{ offsetY: true, maxHeight: 200, maxWidth: 405 }" :items="opciones.pagador" no-data-text="No se encontraron resultados."
                                        @input="validarRelacionExistente()">
                                    </v-autocomplete>
                                </validation-provider>
                                <div class="d-flex align-center tooltip">
                                    <v-tooltip right color="#1867c0">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon dark fab v-bind="attrs" v-on="on" color="#1867c0"> help </v-icon>
                                        </template>
                                        <span>Nombre del pagador.</span>
                                    </v-tooltip>
                                </div>
                            </div>
                            <!-- Campo para ingresar la aseguradora -->
                            <div class="d-flex">
                                <validation-provider class="campo" v-slot="{ errors }" :rules="{ required: true }" ref="aseguradoraValidation">
                                    <v-autocomplete v-model="crearRelacion.aseguradora" label="Aseguradora - Detalle cliente" dense outlined :error-messages="errors"
                                        :menu-props="{ offsetY: true, maxHeight: 200, maxWidth: 405 }" :items="opciones.aseguradora" no-data-text="No se encontraron resultados."
                                        @input="validarRelacionExistente()">
                                    </v-autocomplete>
                                </validation-provider>
                                <div class="d-flex align-center tooltip">
                                    <v-tooltip right color="#1867c0">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon dark fab v-bind="attrs" v-on="on" color="#1867c0"> help </v-icon>
                                        </template>
                                        <span>Nombre de la aseguradora.</span>
                                    </v-tooltip>
                                </div>
                            </div>

                            <div class="d-flex">
                                <!-- Campo para indicar si es fracción a POS -->
                                <validation-provider class="campo" v-slot="{ errors }" :rules="{ required: true }">
                                    <v-select v-model="crearRelacion.fraccionaPos" label="Fracción POS" dense outlined 
                                        :error-messages="errors" :items="opciones.fraccionaPos" :menu-props="{ offsetY: true, maxWidth: 189 }">
                                    </v-select>
                                </validation-provider>
                                <div class="d-flex align-center tooltip">
                                    <v-tooltip right color="#1867c0">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon fab v-bind="attrs" v-on="on" dark color="#1867c0"> help </v-icon>
                                        </template>
                                        <span>Fraccion POS.</span>
                                    </v-tooltip>
                                </div>
                                <!-- Campo para indicar si es fracción de alto costo -->
                                <validation-provider class="campo" v-slot="{ errors }" :rules="{ required: true }">
                                    <v-select v-model="crearRelacion.fraccionaAlto" label="Fracción alto costo" dense outlined
                                        :error-messages="errors" :items="opciones.fraccionaAlto" :menu-props="{ offsetY: true, maxWidth: 189 }">
                                    </v-select>
                                </validation-provider>
                                <div class="d-flex align-center tooltip">
                                    <v-tooltip right color="#1867c0">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon fab v-bind="attrs" v-on="on" dark color="#1867c0"> help </v-icon>
                                        </template>
                                        <span>Fracción alto costo.</span>
                                    </v-tooltip>
                                </div>
                            </div>
                            <!-- Campo para indicar si maneja autorización -->
                            <div class="d-flex">
                                <validation-provider class="campo" v-slot="{ errors }" :rules="{ required: true }">
                                    <v-select v-model="crearRelacion.autorizacion" label="Maneja autorización" dense outlined
                                        :error-messages="errors" :items="opciones.autorizacion" :menu-props="{ offsetY: true, maxWidth: 405 }">
                                    </v-select>
                                </validation-provider>
                                <div class="d-flex align-center tooltip">
                                    <v-tooltip right color="#1867c0">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-icon dark fab v-bind="attrs" v-on="on" color="#1867c0"> help </v-icon>
                                        </template>
                                        <span>Maneja autorización.</span>
                                    </v-tooltip>
                                </div>
                            </div>
                            <!-- Botones del formulario -->
                            <div class="d-flex justify-end">
                                <v-btn class="me-2" text color="error" @click="clear()">Cerrar</v-btn>
                                <v-btn color="success" :disabled="invalid || botonValidacion" text depressed 
                                @click="agregarRelacion()">Guardar</v-btn>
                            </div>
                        </v-form>
                    </validation-observer>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-data-table class="tabla mt-4" :loading="tablaRelacion.loading" :items-per-page.sync="tablaRelacion.itemsPerPage" 
            :headers="tablaRelacion.headers" :footer-props="tablaRelacion.footerProps" :items="tablaRelacion.items"
            :page.sync="tablaRelacion.page" :server-items-length="tablaRelacion.totalPage" fixed-header height="64vh">
            <template v-slot:body="{ items }">
                <tbody>
                    <tr v-for="item in items" v-bind:key="item.idPaas">
                        <td>{{ item.pagador.nombrePagador }} - {{ item.pagador.detalleCliente }}</td>
                        <td>{{ item.aseguradora.nombreAseguradora }} - {{ item.aseguradora.detalleCliente }}</td>
                        <td class="text-center">{{ item.fraccionaPos === true ? 'Si' : 'No' }}</td>
                        <td class="text-center">{{ item.fraccionaAlto === true ? 'Si' : 'No' }}</td>
                        <td class="text-center">{{ item.autorizacion === true ? 'Si' : 'No' }}</td>
                        <td>
                            <v-icon :color="item.eliminado=== false ? 'success' : 'error'">
                                {{ item.eliminado === false ? 'check_circle' : 'cancel' }}
                            </v-icon>
                            {{ item.eliminado === false ? 'Activo' : 'Inactivo' }}
                        </td>
                        <td v-if="item.aseguradora.eliminado === true || item.pagador.eliminado === true" class="text-center">
                            <v-tooltip bottom color="grey">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn v-bind="attrs" v-on="on" icon>
                                        <v-icon color="grey">
                                            {{ item.eliminado === false ? 'person_add_disabled' : 'how_to_reg' }}
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <span>Aseguradora y/o<br>pagador inactivo</span>
                            </v-tooltip>
                        </td>
                        <td v-else class="text-center">
                            <v-tooltip bottom :color="item.eliminado === false ? 'error' : 'success'">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn v-bind="attrs" v-on="on" icon @click="abrirDialogoEstado(item)">
                                        <v-icon :color="item.eliminado === false ? 'error' : 'success'">
                                            {{ item.eliminado === false ? 'person_add_disabled' : 'how_to_reg' }}
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <span>{{ item.eliminado === false ? 'Inactivar' : 'Activar'}}</span>
                            </v-tooltip>
                        </td>
                    </tr>
                </tbody>
            </template>
            <template v-slot:footer.page-text="items">
                {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }}
            </template>
        </v-data-table>

        <!-- Diálogo para cambiar el estado de una relación pagador - aseguradora -->
        <v-dialog v-model="dialogoEstado" persistent max-width="25rem" transition="dialog-bottom-transition">
            <v-card>
                <v-card-title class="encabezadoDialogo">
                    <span class="text-h6"> {{ mensajeEstado }} </span>
                </v-card-title>
                <v-card-text class="pt-5">
                    <div class="d-flex justify-end">
                        <v-btn class="me-2" color="error" text @click="dialogoEstado = false">No</v-btn>
                        <v-btn :disabled="botonValidacion" color="success" depressed text @click="cambiarEstado()">Si</v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>

    </div>
</template>
<!-- #################################################################################### -->
<!-- ###### Sección de Script                                                      ###### -->
<!-- #################################################################################### -->
<script>
import { mapMutations, mapState } from "vuex";
import { required } from 'vee-validate/dist/rules';
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate';

setInteractionMode('eager')
extend('required', {
    ...required,
    message: 'Este campo es requerido.'
});

export default {
    name: "PagadorAseguradora",
    components: {
        ValidationProvider,
        ValidationObserver
    },
    data() {
        return {
            filtro: {
                pagador: '',
                aseguradora: '',
                estado: [false,true],
            },
            opcionEstados: [
                { text: 'Todos', value: [false,true] },
                { text: 'Activo', value: [false] },
                { text: 'Inactivo', value: [true] }
            ],
            dialogoRelacion: false,
            crearRelacion: {
                pagador: '',
                aseguradora: '',
                fraccionaPos: '',
                fraccionaAlto: '',
                autorizacion: ''
            },
            botonValidacion: false,
            opciones: {
                aseguradora: [],
                pagador: [],
                fraccionaPos: [
                    { text: 'Si', value: true },
                    { text: 'No', value: false }
                ],
                fraccionaAlto: [
                    { text: 'Si', value: true },
                    { text: 'No', value: false }
                ],
                autorizacion: [
                    { text: 'Si', value: true },
                    { text: 'No', value: false }
                ]
            },
            tablaRelacion: {
                items: [],
                loading: false,
                headers: [
                    { text: "PAGADOR", align: "left", width: "24%", sortable: false },
                    { text: "ASEGURADORA", align: "left", width: "24%", sortable: false },
                    { text: "FRACCIÓN POS", align: "center", width: "10%", sortable: false },
                    { text: "FRACCIÓN ALTO COSTO", align: "center", width: "10%", sortable: false },
                    { text: "MANEJA AUTORIZACIÓN", align: "center", width: "10%", sortable: false },
                    { text: "ESTADO", align: "left", width: "12%", sortable: false },
                    { text: "ACCIONES", align: "center", width: "10%", sortable: false }
                ],
                footerProps: {
                    'items-per-page-options': [10, 15, 20, 30],
                    'items-per-page-text': 'Items por página:',
                    'show-current-page': true,
                    'show-first-last-page': true
                },
                itemsPerPage: 10,
                page: 1,
                totalPage: 0
            },
            codigoEmpresa: 1,
            lastmodifiedby: '',
            dialogoEstado: false,
            mensajeEstado: '',
            pagadorAseguradoraSeleccionado: []
        }
    },
    computed: {
        ...mapState(["auth", "notify", "busy", "enterprise"]),
    },
    watch: {
        'tablaRelacion.page': function () {
            this.listarPagadorAseguradora();
        },
        'tablaRelacion.itemsPerPage': function () {
            this.tablaRelacion.page = 1;
            this.listarPagadorAseguradora();
        },
        'filtro.pagador': function () {
            this.tablaRelacion.page = 1;
            this.listarPagadorAseguradora();
        },
        'filtro.aseguradora': function () {
            this.tablaRelacion.page = 1;
            this.listarPagadorAseguradora();
        },
        'filtro.estado': function () {
            this.tablaRelacion.page = 1;
            this.listarPagadorAseguradora();
        },
        'dialogoRelacion': function () {
            if (this.dialogoRelacion) {
                this.listarPagadores();
                this.listarAseguradoras();
            }
        },
        'dialogoEstado': function (nextValue) {
            if (nextValue === false) {
                setTimeout(() => {
                    this.botonValidacion = false;
                }, 500)
            }
        }
    },
    methods: {
        ...mapMutations([
        "updateAuth",
        "hideNotify",
        "showSuccess",
        "showBusy",
        "hideBusy",
        ]),
        /**
         * Lista las relaciones pagador-aseguradora en una tabla mediante una solicitud HTTP GET con los siguientes parámetros:
         * page - página actual
         * size - tamaño de la página o número de elementos por página
         * pagador - filtrar por el nombre del pagador
         * aseguradora - filtrar por el nombre de la aseguradora
         * estado - filtrar por el estado de la relación, puede ser activo o inactivo
         * codigoEmpresa - filtrar por el código de empresa al que pertencece el usuario
         */
        listarPagadorAseguradora() {
            this.tablaRelacion.loading = true;
            this.$http
                .get(`msa-administration/pagadorAseguradora/listarPagadoresAseguradoras` , {
                    params: {
                        page: this.tablaRelacion.page -1,
                        size: this.tablaRelacion.itemsPerPage,
                        pagador: `${this.filtro.pagador.toLowerCase()}`,
                        aseguradora: `${this.filtro.aseguradora.toLowerCase()}`,
                        estado: `${this.filtro.estado}`,
                        codigoEmpresa: this.enterprise.code
                    }
                }).then((response) => {
                    this.tablaRelacion.items = response.data.content;
                    this.tablaRelacion.totalPage = response.data.totalElements;
                    this.tablaRelacion.loading = false;
                }).catch((error) => {
                    console.log(error);
                    this.tablaRelacion.loading = false;
                })
        },
        /**
         * Lista los pagadores mediante una solicitud HTTP GET con los siguientes parámetros:
         * codigoEmpresa - Código de la empresa en la que está relacionado el registro
         * estado - Estado del registro(solo se listan los registros con estado activo)
         */
        listarPagadores() {
            this.opciones.pagador = [];
            this.$http
                .get(`msa-administration/pagador/buscar`, {
                    params: {
                        idEmpresa: this.enterprise.code,
                        eliminado: false
                    }
                }).then((response) => {
                    response.data.forEach((item) => {
                        this.opciones.pagador.push({
                            text: `${item.nombrePagador.toUpperCase() } - ${item.detalleCliente}`,
                            value: item.idPagador
                        })
                    })
                }).catch((error) => {
                    console.log(error);
                })
        },
        /**
         * Lista las aseguradoras mediante una solicitud HTTP GET con los siguientes parámetros:
         * codigoEmpresa - El código de la empresa relacionada con el registro.
         * estado - El estado del registro (solo se listan los registros con estado activo).
         * La lista resultante se almacena en el campo "aseguradora" del formulario de creación.
         */
        listarAseguradoras() {
            this.opciones.aseguradora = [];
            this.$http
                .get(`msa-administration/aseguradora/buscar`, {
                    params: {
                        idEmpresa: this.enterprise.code,
                        eliminado: false
                    }
                }).then((response) => {
                    response.data.forEach((item) => {
                        this.opciones.aseguradora.push({
                            text: `${item.nombreAseguradora.toUpperCase()} - ${item.detalleCliente}`,
                            value: item.idAseguradora
                        })
                    })
                }).catch((error) => {
                    console.log(error);
                })
        },
        /**
         * Valida la existencia de una relación pagador-aseguradora por medio de una solicitud HTTP GET con los siguientes 
         * parámetros de coincidencia:
         * pagador - El pagador que se está queriendo ingresar
         * aseguradora - La aseguradora que se está queriendo ingresar
         * codigoEmpresa - El identificador asociado a la empresa
         * Si existe desactiva el botón de guardar y muestra un mensaje de error, de lo contrario permite guardar la relación.
         */
        validarRelacionExistente() {
            if (this.crearRelacion.pagador && this.crearRelacion.aseguradora) {
                this.$http
                    .get(`msa-administration/pagadorAseguradora/validarExistencia`, {
                        params: {
                            pagador: this.crearRelacion.pagador,
                            aseguradora: this.crearRelacion.aseguradora,
                            idEmpresa: this.enterprise.code
                        }
                    })
                    .then((response) => {
                        if (response.data !== null) {
                            this.$refs.aseguradoraValidation.errors = ["La relación ya existe."];
                            this.botonValidacion = true;
                        } else {
                            this.$refs.aseguradoraValidation.errors = [];
                            this.botonValidacion = false;
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    })
            }
        },
        /**
         * Agrega una nueva relación pagador-aseguradora enviando una solicitud 
         * HTTP POST con los datos proporcionados.
         */
        agregarRelacion() {
            this.botonValidacion = true;
            const relacion = {
                pagador: {
                    idPagador: this.crearRelacion.pagador
                },
                aseguradora: {
                    idAseguradora: this.crearRelacion.aseguradora
                },
                fraccionaPos: this.crearRelacion.fraccionaPos,
                fraccionaAlto: this.crearRelacion.fraccionaAlto,
                autorizacion: this.crearRelacion.autorizacion,
                estado: false,
                empresa: {
                    idEmpresa: this.enterprise.code
                },
                lastmodifiedby: this.lastmodifiedby
            }
            this.$http
                .post(`msa-administration/pagadorAseguradora/guardar`, relacion)
                .then(() => {
                    this.listarPagadorAseguradora();
                    this.dialogoRelacion = false;
                    this.clear();
                }).catch((error) => {
                    console.log(error);
                })
        },
        /**
         * Abre un diálogo para cambiar el estado de una relación (activar o inactivar) y configura el mensaje correspondiente.
         * @param {*} item - El objeto de la relación a la que se le cambiará el estado.
         */
        abrirDialogoEstado(item) {
            this.dialogoEstado = true;
            this.pagadorAseguradoraSeleccionado = item;
            this.mensajeEstado = this.pagadorAseguradoraSeleccionado.eliminado === false ? '¿Desea inactivar la relación?' : '¿Desea activar la relación?';
        },
        /**
         * Cambia el estado de una relación entre false (activo) y true (inactivo) por medio de una solicitud HTTP PUT y 
         * actualiza este cambio en el servidor. Luego, se lista de nuevo las relaciones y se cierra el diálogo de cambio de estado.
         */
        cambiarEstado() {
            this.botonValidacion = true;
            this.pagadorAseguradoraSeleccionado.eliminado = (this.pagadorAseguradoraSeleccionado.eliminado == false) ? true : false;
            const estado = {
                idPaas: this.pagadorAseguradoraSeleccionado.idPaas,
                eliminado: this.pagadorAseguradoraSeleccionado.eliminado,
                lastmodifiedby: this.lastmodifiedby
            }
            this.$http
                .put(`msa-administration/pagadorAseguradora/actualizarEstado`, estado)
                .then(() => {
                    this.listarPagadorAseguradora();
                    this.dialogoEstado = false;
                }).catch((error) => {
                    console.log(error);
                })
        },
        /**
         * Reestablece varias variables y propiedades a sus valores predeterminados, también reestablece los 
         * campos de entrada de un formulario a su estado inicial.
         * Se utiliza para limpiar y preparar el estado del programa antes de realizar una nueva operación.
         */
        clear() {
            this.$refs.observer.reset();
            this.dialogoRelacion = false;
            this.crearRelacion.pagador = '';
            this.crearRelacion.aseguradora = '';
            this.crearRelacion.fraccionaPos = '';
            this.crearRelacion.fraccionaAlto = '';
            this.crearRelacion.autorizacion = '';
            this.botonValidacion = false;
        }
    },
    mounted() {
        this.lastmodifiedby = this.auth.username.toUpperCase();
        this.listarPagadorAseguradora();
    }
}
</script>
<!-- #################################################################################### -->
<!-- ###### Sección de Style                                                       ###### -->
<!-- #################################################################################### -->
<style scoped>
.contenido {
    padding: 1rem;
    width: 100%;
    height: 86vh;
}
.filtro {
    width: 25%;
}
.encabezadoDialogo{
    background-color: #1867c0;
    color: white;
}
.campo {
    width: 100% !important;
}
.tooltip {
    height: 2.5rem;
    margin-left: 3px;
}
::v-deep .tabla div table thead tr th {
    background-color: rgb(223, 223, 223) !important;
}
::v-deep .tabla div table thead tr th span {
    font-weight: bold;
    color: black !important;
}
::v-deep .tabla .v-data-footer {
    width: 100%;
}
</style>